export async function webinarRequest(data) {
	try {
		const response = await fetch(`${import.meta.env.VITE_API_URL}local/ajax/file.php`, {
			method: 'POST',
			body: data,
		})
			.then((data) => {
				return data.json();
			})
			.then((data) => {
				if (data.success && data.success === 'Y') {
					return {
						status: 'success',
					};
				}
				if (data.error) {
					return {
						status: 'error',
						message: `<h4>Ошибка</h4><p>${data.error}</p>`,
					};
				}
			});
		return response;
	} catch (error) {
		return {
			status: 'error',
			message: `<h4>Ошибка</h4><p>${error}</p>`,
		};
	}
}
