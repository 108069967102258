import { humanizeDate } from '@/features/day/day';
import { render } from '@/shared/render/render';

const formElement = document.querySelector('.modal-webinar-single .modal-webinar__form');
const workshopsElement = formElement?.querySelector('.modal-webinar__workshops');
const submitElement = formElement?.querySelector('.modal-webinar-form__submit');

// prettier-ignore
const createWorkshopItems = (webinar) => `
	${webinar.events.map((event) => `
		<li class="modal-webinar-workshop__item">
			<div class="form-checkbox js-form-element">
				<div class="form-checkbox__control">
					<input
						class="form-checkbox__input"
						id="${event.id}"
						type="checkbox"
						name="${event.name}"
						data-type="form-checkbox"
						data-group="${webinar.id}"
					>
					<div class="form-checkbox__vue"><i class="o2k-icon o2k-icon-check"></i></div>
				</div>
				<label class="form-checkbox__label" for="${event.id}">
					${event.clarify ? '<strong>Уточняется</strong>' : `<b>${humanizeDate(event.date, 'DD MMMM YYYY')}</b>`}&nbsp;—
					${webinar.multi ? `${event.title}` : `${webinar.title}`}
				</label>
			</div>
		</li>`
	).join('')}
`;

function createWebinarTemplate(webinar) {
	return `
		<li class="modal-webinar-workshop" id="${webinar.id}">
			${webinar.multi ? `<p class="modal-webinar-workshop__title">${webinar.title}</p>` : ''}
			<ul class="modal-webinar-workshop__list">
				${createWorkshopItems(webinar)}
			</ul>
			<div class="modal-webinar-workshop__footer">
				<button
					class="modal-webinar-workshop__toggle"
					type="button"
					aria-expanded="false"
				>
					<span>Написать вопросы спикеру</span><i class="o2k-icon o2k-icon-arrow_d"></i>
				</button>
				<div class="modal-webinar-workshop__textarea">
					<div class="form-element js-form-element">
					<div class="form-element__field">
						<textarea
							class="form-textarea"
							id="${webinar.id}-question"
							name="${webinar.id}-question"
							placeholder="Вы&nbsp;можете написать за&nbsp;ранее вопросы, на&nbsp;которые хотели&nbsp;бы получить ответы во&nbsp;время мероприятия"
							autocomplete="off"
						></textarea>
					</div>
					<div class="form-element__message js-form-element__message"></div>
					</div>
				</div>
			</div>
		</li>
	`;
}

export const initModalWebinarSingle = () => {
	formElement?.addEventListener('change', () => {
		const checkboxesElements = formElement.querySelectorAll(
			'.modal-webinar-workshop__item .form-checkbox__input',
		);

		let workshopCount = 0;
		checkboxesElements.forEach((checkbox) => {
			if (checkbox.checked) {
				workshopCount++;
			}
			if (workshopCount === 0) {
				submitElement?.classList.add('disabled');
			} else {
				submitElement?.classList.remove('disabled');
			}
		});
	});

	document.addEventListener('click', (e) => {
		if (e.target.classList.contains('card-main__status')) {
			formElement?.reset();
			const articleElement = e.target.parentElement.parentElement.parentElement.parentElement;
			if (workshopsElement) {
				workshopsElement.innerHTML = '';
				render(
					createWebinarTemplate(JSON.parse(articleElement.dataset.json)),
					workshopsElement,
				);
			}
		}
	});
};
