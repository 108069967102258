import '@/app/app.css';
import './webinars.css';

import '@/app/app';
import { WebinarForm } from '@/features/modal/modal-webinar/modal-webinar';
import { SubscribeForm } from '@/features/subscribe/subscribe';
import { initLinkScroll } from '@/shared/scroll/scroll';
import { toggleSeasonList } from '@/features/webinars/webinars-season/webinars-season';
import { initNavigationGrid } from '@/features/navigation/navigation-grid/navigation-grid';
import { initModalWebinarWorkshop } from '@/features/modal/modal-webinar/modal-webinar-workshop/modal-webinar-workshop';
import { initModalWebinarSingle } from '@/features/modal/modal-webinar/modal-webinar-single/modal-webinar-single';
import { initModalWebinarMulti } from '@/features/modal/modal-webinar/modal-webinar-multi/modal-webinar-multi';
import { toggleModalWebinarChosenList } from '@/features/modal/modal-webinar/modal-webinar-chosen/modal-webinar-chosen';

window.addEventListener('DOMContentLoaded', () => {
	initLinkScroll();
	toggleSeasonList();
	initNavigationGrid();
	initModalWebinarWorkshop();
	initModalWebinarSingle();
	initModalWebinarMulti();
	toggleModalWebinarChosenList();

	document.querySelectorAll('.js-webinar-form').forEach((element) => new WebinarForm(element));
	document.querySelectorAll('.js-subscribe-form').forEach((element) => new SubscribeForm(element));
});
