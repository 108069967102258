import { classInstance } from '@/shared/helpers/helpers';
import { Form } from '@/shared/form/form';

import { webinarRequest } from '@/api/request/webinar';

export class WebinarForm {
	constructor(selector) {
		this.$container = selector;

		if (!this.$container) return;

		this.init();
	}

	init() {
		classInstance.set(this.$container, { contactForm: this });
		this.$button = this.$container.querySelector('button[type="submit"]');
		this.instance = new Form(this.$container);

		this.$container.addEventListener('submit', this.submitHandler.bind(this));
	}

	destroy() {
		classInstance.del(this.$container, 'contactForm');
		this.$container.removeEventListener('submit', this.submitHandler);
	}

	reinit() {
		this.destroy();
		this.init();
	}

	submitHandler(event) {
		event.preventDefault();

		if (!this.instance.formCheck(false)) return;

		this.$button.classList.add('loading');

		webinarRequest(new FormData(this.$container)).then((response) => {
			this.$button.classList.remove('loading');
			if (response.ok) {
				app.notify.append({
					type: response.status,
					delay: '10000',
					content: response.message,
				});
			} else {
				app.modal.open('#modal-webinar-success');
				setTimeout(() => {
					document.querySelector('.modal-webinar__return').click();
					document.querySelector('.modal-webinar__submit').classList.add('disabled');
				}, 100);
			}
			this.$container.reset();
		});
	}
}
