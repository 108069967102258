const listElement = document.querySelector('.webinars-season__list');
const toggleElement = document.querySelector('.webinars-season__toggle');
const requestOpenElement = document.querySelector('.webinars-season__request-open');
const formElement = document.querySelector('.modal-webinar-multi .modal-webinar__form');
const returnElement = formElement?.querySelector('.modal-webinar__return');
const submitElement = formElement?.querySelector('.modal-webinar__submit');

const onToggleClick = () => {
	if (toggleElement.getAttribute('aria-expanded') === 'false') {
		toggleElement.setAttribute('aria-expanded', 'true');
		toggleElement.querySelector('span').textContent = 'Свернуть';
		listElement.classList.add('opened');
	} else {
		toggleElement.setAttribute('aria-expanded', 'false');
		toggleElement.querySelector('span').textContent = 'Все сезоны';
		listElement.classList.remove('opened');
	}
};

const onRequestOpenClick = () => {
	const workshopElements = formElement.querySelectorAll('.modal-webinar-workshop');
	workshopElements.forEach((workshop) => workshop.classList.remove('checked'));

	if (formElement) formElement.reset();
	if (returnElement) returnElement.click();
	submitElement.classList.add('disabled');
};

export const toggleSeasonList = () => {
	toggleElement?.addEventListener('click', onToggleClick);
	requestOpenElement?.addEventListener('click', onRequestOpenClick);
};
