import { render } from '@/shared/render/render';

const formElement = document.querySelector('.modal-webinar-multi .modal-webinar__form');
const submitElement = formElement?.querySelector('.modal-webinar__submit');
const step1Element = formElement?.querySelector('.modal-webinar__step1');
const step2Element = formElement?.querySelector('.modal-webinar__step2');
const stepElement = formElement?.querySelector('.modal-webinar__step');
const labelElement = formElement?.querySelector('.modal-webinar__label');
const chosenButtonElement = formElement?.querySelector('.modal-webinar__chosen-button');
const returnElement = formElement?.querySelector('.modal-webinar__return');
const chosenElement = formElement?.querySelector('.modal-webinar-chosen');

// prettier-ignore
function createChosenItems(workshop) {
	const titleElement = workshop.querySelector('.modal-webinar-workshop__title');
	const checkElements = workshop.querySelectorAll('.form-checkbox__input:checked');
	return `
	<li class="modal-webinar-chosen__item">
		${titleElement ? `<p>${titleElement.textContent}` : ''}
		${Array.from(checkElements).map((el) => `
			<p>${el.parentElement.parentElement.querySelector('.form-checkbox__label').innerHTML}</p>
		`).join('')}
		</li>
	`;
}

export const initModalWebinarMulti = () => {
	formElement?.addEventListener('change', (e) => {
		const checkboxesElements = formElement.querySelectorAll(
			'.modal-webinar-workshop__item .form-checkbox__input',
		);
		let workshopCount = 0;
		checkboxesElements.forEach((checkbox) => {
			if (checkbox.checked) {
				workshopCount++;
			}
			if (workshopCount === 0) {
				submitElement.classList.add('disabled');
			} else {
				submitElement.classList.remove('disabled');
			}
		});

		const parentElement = document.querySelector(`#${e.target.dataset.group}`);

		if (parentElement) {
			const checkedElements = parentElement.querySelectorAll('.form-checkbox__input:checked');

			if (checkedElements && checkedElements.length > 0) {
				parentElement.classList.add('checked');
			} else {
				parentElement.classList.remove('checked');
			}
		}
	});

	const oSubmitClick = () => {
		const checkboxesElements = formElement.querySelectorAll(
			'.modal-webinar-workshop__item .form-checkbox__input:checked',
		);
		const workshopCheckedElements = formElement.querySelectorAll(
			'.modal-webinar-workshop.checked',
		);

		step1Element.style.display = 'none';
		step2Element.style.display = 'grid';
		stepElement.innerHTML = 'Шаг 2&nbsp;из&nbsp;2';
		labelElement.textContent = 'Укажите личные данные';
		chosenButtonElement.style.display = 'flex';
		chosenButtonElement.querySelector('span').textContent =
			`Выбрано: ${checkboxesElements.length}`;

		Array.from(workshopCheckedElements)
			.map((workshop) => {
				render(createChosenItems(workshop), chosenElement);
			})
			.join('');
	};

	const onReturnClick = () => {
		step1Element.style.display = 'grid';
		step2Element.style.display = 'none';
		stepElement.innerHTML = 'Шаг 1&nbsp;из&nbsp;2';
		labelElement.textContent = 'Выберите одно или несколько мероприятий';
		chosenButtonElement.style.display = 'none';
		chosenButtonElement.setAttribute('aria-expanded', 'false');
		chosenElement.innerHTML = '';
	};

	submitElement?.addEventListener('click', oSubmitClick);
	returnElement?.addEventListener('click', onReturnClick);
};
