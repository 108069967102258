const toggleGrid = document.querySelectorAll('.navigation-grid__toggle')[0];
const toggleColumns = document.querySelectorAll('.navigation-grid__toggle')[1];
const cards = document.querySelector('.cards');

export const initNavigationGrid = () => {
	document.addEventListener('click', (e) => {
		if (e.target === toggleGrid) {
			toggleGrid.setAttribute('aria-expanded', 'true');
			toggleColumns.setAttribute('aria-expanded', 'false');
			cards.classList.remove('cards--columns');
		}

		if (e.target === toggleColumns) {
			toggleColumns.setAttribute('aria-expanded', 'true');
			toggleGrid.setAttribute('aria-expanded', 'false');
			cards.classList.add('cards--columns');
		}
	});
};
